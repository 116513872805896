<!--数据分析课程详情-->
<template>
  <div>
    <template
      v-if="
        $route.name == 'dataAnalysisDetail' || $route.name == 'dataStatistics'
      "
    >
      <div class="course-name" v-if="!$route.query.isopencourse">
        <div class="flex-center name">
          <span class="t1">{{ scheduleInfo.name }}</span>
          <span class="t2">
            {{ $route.query.type == 1 ? '普通模式' : '极速模式' }}
          </span>
        </div>
        <div class="time">
          {{ scheduleInfo.live_status | getLiveStatus }} |
          {{ scheduleInfo.startTime | formatTimeStamp('yyyy-MM-dd hh:mm') }} -
          {{ scheduleInfo.endTime | formatTimeStamp('yyyy-MM-dd hh:mm') }} |
          {{ scheduleInfo.teacher_name }}
        </div>
      </div>
      <!--概况-->
      <div class="bgf mt20">
        <template v-if="$route.query.type == 1 && !$route.query.isopencourse">
          <div class="flex-center toggleCourseType">
            <div
              class="item"
              :class="{ selected: course_type == 1 }"
              @click="toggleCourseType(1)"
            >
              直播统计
            </div>
            <div
              class="item"
              :class="{ selected: course_type == 4 }"
              @click="toggleCourseType(4)"
            >
              回放统计
            </div>
          </div>
        </template>
        <template v-if="course_type == 4">
          <div class="flex-center back-video-setting">
            <div class="title">回放设置</div>
            <el-switch
              v-model="scheduleInfo.live_recall"
              @change="changeLiveRecall"
              active-value="1"
              inactive-value="2"
            ></el-switch>
            <div class="prompt">开启回放后，直播结束后直播间将播放回放视频</div>
          </div>
        </template>
        <template v-if="!$route.query.isopencourse">
          <div class="title pad20">
            {{ course_type != 4 ? '直播' : '回放' }}概况
          </div>
          <template v-if="course_type != 4">
            <div class="flex-center flex-list">
              <digitalDisplay
                text="累计在线人数"
                :number="surveyInfo.ol_number"
                prompt="直播创建开始至今，进入直播间的学员总数（更新频率为5分钟）"
              ></digitalDisplay>
              <digitalDisplay
                text="累计在线次数"
                :number="surveyInfo.ol_times"
                prompt="直播创建开始至今，学员进入直播间的总次数（更新频率为5分钟）"
              ></digitalDisplay>
              <digitalDisplay
                text="人均在线时长"
                :number="surveyInfo.average_ol_times | formatTimeLength"
                prompt="直播创建开始至今学员进入直播间的人均停留时长；计算公式：所有用户在线总时长/累计在线人数（更新频率为5分钟)"
              ></digitalDisplay>
            </div>
            <div class="flex-center flex-list">
              <digitalDisplay
                text="累计学习人数"
                :number="surveyInfo.study_number"
                prompt="直播创建开始至今，学员进入直播间学习总人数（更新频率为5分钟)直播中进入直播间观看直播才算学习行为，直播前进入直播间不算"
              ></digitalDisplay>
              <digitalDisplay
                text="累计学习次数"
                :number="surveyInfo.study_times"
                prompt="直播创建开始至今，学员进入直播间学习总次数（更新频率为5分钟)直播中进入直播间播放直播视频才算学习行为，直播前进入直播间不算"
              ></digitalDisplay>
              <digitalDisplay
                text="人均学习时长"
                :number="surveyInfo.average_study_times | formatTimeLength"
                prompt="直播创建开始至今学员进入直播间学习的人均时长；计算公式：所有用户学习总时长/总学习人数（更新频率为1天)"
              ></digitalDisplay>
            </div>
          </template>
          <template v-else>
            <div class="flex-center flex-list">
              <digitalDisplay
                text="累计在线人数"
                :number="surveyInfo.ol_number"
                prompt="进入回放页的总人数，包含已拉黑、已踢出的人数"
              ></digitalDisplay>
              <digitalDisplay
                text="累计在线次数"
                :number="surveyInfo.ol_times"
                prompt="进入回放页的总次数，包含已拉黑、已踢出的人数"
              ></digitalDisplay>
              <digitalDisplay
                text="人均在线时长"
                :number="surveyInfo.average_ol_times | formatTimeLength"
                prompt="计算公式：所有用户在线总时长/总在线人数（更新频率为1天)"
              ></digitalDisplay>
            </div>
            <div class="flex-center flex-list">
              <digitalDisplay
                text="累计学习人数"
                :number="surveyInfo.study_number"
                prompt="进入回放页播放视频的总人数，包含已拉黑、已踢出的人数；播放视频才算学习，只进入页面不算"
              ></digitalDisplay>
              <digitalDisplay
                text="累计学习次数"
                :number="surveyInfo.study_times"
                prompt="进入回放页播放视频的总次数，包含已拉黑、已踢出的人数；放视频才算学习，只进入页面不算"
              ></digitalDisplay>
              <digitalDisplay
                text="人均学习时长"
                :number="surveyInfo.average_study_times | formatTimeLength"
                prompt="计算公式：所有用户学习总时长/总学习人数（更新频率为1天)"
              ></digitalDisplay>
            </div>
          </template>
          <template v-if="course_type != 4 && course_type != 5">
            <div class="flex-center flex-list">
              <digitalDisplay
                text="累计打赏(元)"
                :number="surveyInfo.gift_prices"
              ></digitalDisplay>
              <digitalDisplay style="visibility: hidden"></digitalDisplay>
              <digitalDisplay style="visibility: hidden"></digitalDisplay>
            </div>
          </template>
          <template v-else-if="course_type == 5">
            <div class="flex-center flex-list">
              <digitalDisplay
                text="连麦人数"
                :number="surveyInfo.call_number"
                prompt="上课中的总连麦人数，只记录连麦成功的人数，不包含举手未通过的人数"
              ></digitalDisplay>
              <digitalDisplay style="visibility: hidden"></digitalDisplay>
              <digitalDisplay style="visibility: hidden"></digitalDisplay>
            </div>
          </template>
        </template>

        <!--观看人数-->
        <div v-if="course_type != 4" class="title pad20 flex-center">
          直播间人数
          <helpIcon
            style="margin-left: 6px"
            content="在线人数：直播创建开始至今，进入直播间的人数峰值；仅进入页面算在线人数<br/>
                    学习人数：直播创建开始至今，学员进入直播间学习总人数，直播中进入直播间观看直播才算学习行为
                    仅保留直播开始时间后30天的数据"
          ></helpIcon>
        </div>
        <div v-else class="title pad20 flex-center">
          回放观看人数
          <helpIcon
            style="margin-left: 6px"
            prompt="生成回放至今，回放的在线和学习峰值人数；仅保留直播开始时间后30天的数据"
          ></helpIcon>
        </div>
        <div>
          <onlineMember
            :isopencourse="$route.query.isopencourse"
            :id="$route.query.sl_id"
            :type="course_type"
          ></onlineMember>
        </div>
        <!-- 码率 -->
        <div v-if="$route.query.isopencourse">
          <div class="title pad20 flex-center">直播码率图</div>
          <div class="line-chart">
            <lineChart :options="list3"></lineChart>
          </div>
        </div>
        <!-- 帧率 -->
        <div v-if="$route.query.isopencourse">
          <div class="title pad20 flex-center">直播帧率图</div>
          <div class="line-chart">
            <lineChart :options="list4"></lineChart>
          </div>
        </div>
        <template v-if="!$route.query.isopencourse">
          <!--观看详情-->
          <div class="title pad20 flex-center">
            {{ course_type != 4 ? '直播' : '回放' }}学习详情
            <helpIcon
              v-if="course_type == 4"
              style="margin-left: 6px"
              prompt="按全部回放片段统计，如有多个回放片段，系统自动累加"
            ></helpIcon>
          </div>
          <div class="pad20">
            <el-form
              style="text-align: right"
              :inline="true"
              @submit.native.prevent
            >
              <el-form-item>
                <el-button
                  style="margin-bottom: 10px"
                  @click="exportTable"
                  type="primary"
                  size="medium"
                >
                  导出
                </el-button>
              </el-form-item>
              <el-form-item>
                <el-date-picker
                  size="medium"
                  style="width: 292px; margin-left: 20px"
                  v-model="exportTime"
                  type="daterange"
                  @change="timepick"
                  value-format="timestamp"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                ></el-date-picker>
              </el-form-item>
              <el-form-item>
                <div class="flex-center">
                  <el-select v-model="post1.searchType" placeholder="请选择">
                    <el-option
                      v-for="(item, index) in ['昵称', '真实姓名']"
                      :key="item"
                      :label="item"
                      :value="index + 1"
                    ></el-option>
                  </el-select>
                  <el-input
                    class="searchInput"
                    v-model="post1.name"
                    :placeholder="
                      post1.searchType == 2
                        ? '输入真实姓名搜索'
                        : '输入昵称搜索'
                    "
                  ></el-input>
                </div>
              </el-form-item>
            </el-form>
            <pagination2
              :option="post1"
              url="/Record/liveDetails"
              @complete="liveDetailsCount = arguments[0].count"
            >
              <template scope="{ tableData: { list } }">
                <el-table
                  :data="list"
                  :header-cell-style="{
                    background: 'rgba(245,245,245,1)',
                    color: '#333333',
                  }"
                >
                  <el-table-column label="昵称" prop="user_name">
                    <template slot-scope="scope">
                      <div class="flex-center invite-nick">
                        <img class="photo" :src="scope.row.user_photo" alt />
                        <div class="name">{{ scope.row.user_name }}</div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="真实姓名"
                    prop="student_remarks"
                  ></el-table-column>
                  <el-table-column label="首次进入时间" prop="c_time">
                    <template slot-scope="scope">
                      {{
                        scope.row.c_time | formatTimeStamp('yyyy-MM-dd hh:mm')
                      }}
                    </template>
                  </el-table-column>
                  <el-table-column label="总学习时长" prop="learning_duration">
                    <template slot-scope="scope">
                      {{ scope.row.learning_duration | formatTimeLength }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="总学习次数"
                    prop="learning_num"
                  ></el-table-column>
                  <el-table-column label="操作">
                    <template slot-scope="scope">
                      <router-link
                        :to="
                          `dataAnalysisDetail/viewDetail/id/${scope.row.id}?type=${course_type}`
                        "
                        class="toDetail"
                      >
                        详情
                      </router-link>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </pagination2>
          </div>
        </template>
      </div>

      <template v-if="!$route.query.isopencourse">
        <!--在线人次地理位置分布-->
        <div class="flex-center mt20 function-views">
          <div class="map">
            <div class="title flex-center">
              在线人次地理位置分布
              <helpIcon
                style="margin-left: 6px"
                prompt="进入页面即算人次，同一用户在不同地区算多个人次"
              ></helpIcon>
            </div>
            <chinaMap :dataList="mapDistribution"></chinaMap>
          </div>
          <div class="personnelRatio">
            <div class="title flex-center">
              学习终端系统人次占比
              <helpIcon
                style="margin-left: 6px"
                prompt="同一用户使用不同终端学习算多个人次"
              ></helpIcon>
            </div>
            <pieChart
              :options="pieOption"
              :pieData="view_numbers"
              style="
                width: 100%;
                bottom: 20px;
                left: 0;
                top: 39px;
                height: auto;
              "
            ></pieChart>
          </div>
        </div>
        <!--邀请 打赏-->
        <template v-if="course_type == 1 || $route.query.isopencourse">
          <div class="flex-center mt20 function-views">
            <div class="invite">
              <div class="title">打赏记录</div>
              <pagination2
                :option="post2"
                url="/Record/giftRecord"
                :allFn="true"
              >
                <template
                  v-slot:other="{
                    tableData: { gift_prices, people_count, gift_count },
                  }"
                >
                  <div class="flex-center table-menu">
                    <div class="item">
                      <div class="title">打赏总金额(元)</div>
                      <div class="number">{{ gift_prices || '0.00' }}</div>
                    </div>
                    <div class="item">
                      <div class="title">打赏总人数</div>
                      <div class="number">{{ people_count || '0' }}</div>
                    </div>
                    <div class="item">
                      <div class="title">打赏总次数</div>
                      <div class="number">{{ gift_count || '0' }}</div>
                    </div>
                  </div>
                </template>
                <template v-slot:default="{ tableData: { list } }">
                  <el-table
                    :data="list"
                    :header-cell-style="{
                      background: 'rgba(245,245,245,1)',
                      color: '#333333',
                    }"
                  >
                    <el-table-column label="排名">
                      <template slot-scope="scope">
                        {{ scope.$index + 1 }}
                      </template>
                    </el-table-column>
                    <el-table-column label="昵称" prop="nick">
                      <template slot-scope="scope">
                        <div class="flex-center invite-nick">
                          <img class="photo" :src="scope.row.uphoto" alt />
                          <span class="name">{{ scope.row.uname }}</span>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="打赏金额(元)"
                      prop="price"
                    ></el-table-column>
                  </el-table>
                </template>
              </pagination2>
            </div>

            <!-- <div class="reward">
            <div class="title">打赏记录</div>
            <el-table
              :data="tableData"
              :header-cell-style="{
                background: 'rgba(245,245,245,1)',
                color: '#333333'
              }"
            >
              <el-table-column label="排名">
                <template slot-scope="scope">{{ scope.$index + 1 }}</template>
              </el-table-column>
              <el-table-column label="昵称" prop="nick"></el-table-column>
              <el-table-column label="邀请次数" prop="money"></el-table-column>
            </el-table>
          </div>-->
          </div>
        </template>
      </template>

      <loading v-show="loading" leftPos="0"></loading>
    </template>
    <router-view />
  </div>
</template>
<script>
// import bg from '@/mixin/background'
import helpIcon from '@/components/helpIcon'
import digitalDisplay from '@/components/dataAnalysis/digitalDisplay'
import onlineMember from '@/components/dataAnalysis/onlineMember'
import chinaMap from '@/components/dataAnalysis/chinaMap'
import pieChart from '@/components/dataAnalysis/pieChart'
import lineChart from '@/components/dataAnalysis/line'

export default {
  name: 'dataAnalysisDetail',

  // mixins: [bg],

  components: {
    chinaMap,
    helpIcon,
    pieChart,
    onlineMember,
    digitalDisplay,
    lineChart,
  },

  data() {
    return {
      analysisIncrement: {},
      //码率
      list3: {},
      loading: false,
      // 邀请 和 打赏
      post1: {},
      post2: {},
      liveDetailsCount: 0,
      //帧率
      list4: {},

      exportTime: '',
      course_type: this.$route.query.type,

      surveyInfo: {}, // 直播概况
      scheduleInfo: {}, // 课程信息
      view_numbers: [], // 观看终端系统次数占比
      mapDistribution: [], // 地理位置分布

      pieOption: {
        series: [
          {
            name: '访问来源',
          },
        ],
      },
    }
  },
  watch: {
    analysisIncrement: {
      handler() {
        this.list4 = {
          legend: {
            left: 0,
            data: ['视频帧率', '音频帧率'],
          },
          xAxis: {
            data: this.analysisIncrement.times,
          },
          yAxis: {
            minInterval: null,
            axisLabel: {
              formatter(value) {
                return value + 'ftps'
              },
            },
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              // 坐标轴指示器，坐标轴触发有效
              type: 'line', // 默认为直线，可选为：'line' | 'shadow'
            },
            formatter: function(params) {
              var texts = ''
              for (var i = 0; i < params.length; i++) {
                if (i == 0) {
                  texts += params[i].name + '<br>'
                }

                texts +=
                  params[i].marker +
                  ' ' +
                  params[i].seriesName +
                  ': ' +
                  params[i].value +
                  ' ' +
                  'ftps' +
                  '<br>'
              }
              return texts
            },
          },
          series: [
            {
              name: '视频帧率',
              type: 'line',
              data: this.analysisIncrement.data3,
              lineStyle: {
                color: '#F8B832',
              },
              itemStyle: {
                color: '#F8B832',
                borderWidth: 4,
              },
              smooth: true,
            },
            {
              name: '音频帧率',
              type: 'line',
              data: this.analysisIncrement.data4,
              lineStyle: {
                color: '#B675F0',
              },
              itemStyle: {
                color: '#B675F0',
                borderWidth: 4,
              },
              smooth: true,
            },
          ],
        }
      },
      deep: true,
      immediate: true,
    },
    $route: {
      handler(to) {
        if (to.name == 'dataAnalysisDetail') {
          this.init()
        }
        // if (this.$route.query.isopencourse) {
        //   this.$root.$children[0].childPageOptions[
        //     this.$root.$children[0].childPageOptions.length - 2
        //   ].name = '直播记录'
        // }
      },
      immediate: true,
    },
  },

  filters: {
    getLiveStatus(value) {
      let text
      switch (Number(value)) {
        case 2:
          text = '已结束'
          break
        case 3:
          text = '未开始'
          break
        case 4:
          text = '直播中'
          break
        default:
          text = '全部'
      }
      return text
    },
  },

  created() {
    if (this.$route.query.isopencourse) {
      this.incrementData()
    }
  },

  methods: {
    init() {
      this.getData()
      this.mapData()
      this.exportTime = ''
      this.post1 = this.getPostOption()
      this.post2 = this.getPostOption()
    },

    // 流量增长趋势
    incrementData() {
      this.$http({
        url: '/Record/livePush',
        data: {
          live_history_id: this.$route.query.sl_id,
        },
        callback: ({ data }) => {
          this.analysisIncrement = {
            times: data.map(item => item[0]),
            data3: data.map(item => item[3]),
            data4: data.map(item => item[4]),
          }
          this.list3 = {
            legend: {
              left: 0,
              data: ['视频码率', '音频码率'],
            },
            xAxis: {
              data: data.map(item => item[0]),
            },
            yAxis: {
              minInterval: null,
              axisLabel: {
                formatter(value) {
                  if (value >= 1000000) {
                    return value / 1000 / 1000 + 'Mbps'
                  } else if (value >= 1000) {
                    return value / 1000 + 'kbps'
                  } else {
                    return value + 'bps'
                  }
                },
              },
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                // 坐标轴指示器，坐标轴触发有效
                type: 'line', // 默认为直线，可选为：'line' | 'shadow'
              },
              formatter: function(params) {
                var texts = ''
                if (params) {
                  for (var i = 0; i < params.length; i++) {
                    if (i == 0) {
                      texts += params[i].name + '<br>'
                    }
                    let danwei
                    if (params[i].value >= 1000000) {
                      danwei =
                        Math.floor((params[i].value / 1000 / 1000) * 100) /
                          100 +
                        ' ' +
                        'Mbps'
                    } else if (params[i].value >= 1000) {
                      danwei =
                        Math.floor((params[i].value / 1000) * 100) / 100 +
                        ' ' +
                        'kbps'
                    } else {
                      danwei = params[i].value + ' ' + 'bps'
                    }
                    texts +=
                      params[i].marker +
                      params[i].seriesName +
                      ': ' +
                      danwei +
                      '<br>'
                  }
                }
                return texts
              },
            },
            series: [
              {
                name: '视频码率',
                type: 'line',
                data: data.map(item => item[1]),
                lineStyle: {
                  color: '#00D966',
                },
                itemStyle: {
                  color: '#00D966',
                  borderWidth: 4,
                },
                smooth: true,
              },
              {
                name: '音频码率',
                type: 'line',
                data: data.map(item => item[2]),
                lineStyle: {
                  color: '#0098FF',
                },
                itemStyle: {
                  color: '#0098FF',
                  borderWidth: 4,
                },
                smooth: true,
              },
              // {
              //   name: '视频帧率',
              //   type: 'line',
              //   data: data.map((item) => item[3]),
              //   lineStyle: {
              //     color: '#F8B832',
              //   },
              //   itemStyle: {
              //     color: '#F8B832',
              //     borderWidth: 4,
              //   },
              //   smooth: true,
              // },
              // {
              //   name: '音频帧率',
              //   type: 'line',
              //   data: data.map((item) => item[4]),
              //   lineStyle: {
              //     color: '#B675F0',
              //   },
              //   itemStyle: {
              //     color: '#B675F0',
              //     borderWidth: 4,
              //   },
              //   smooth: true,
              // },
            ],
          }
        },
      })
    },

    getPostOption() {
      let val = this.$route.query.isopencourse
        ? { live_history_id: this.$route.query.sl_id }
        : {
            sl_id: this.$route.query.sl_id,
          }
      return Object.assign({}, val, {
        page: 1,
        course_type: this.course_type,
        course_id: this.$route.query.course_id,
        name: '',
        type: this.course_type,
        startTime: 0,
        endTime: 0,
        searchType: 1,
      })
    },

    getData() {
      this.loading = true
      let val = this.$route.query.isopencourse
        ? { live_history_id: this.$route.query.sl_id }
        : {
            sl_id: this.$route.query.sl_id,
          }
      this.$http({
        url: '/Record/liveSurvey',
        data: _.assign({}, val, {
          type: this.course_type,
        }),
        callback: ({ data: { surveyInfo, scheduleInfo, view_numbers } }) => {
          this.surveyInfo = surveyInfo
          this.scheduleInfo = scheduleInfo
          this.view_numbers = view_numbers
          this.loading = false
        },
      })
    },

    // 回放状态
    changeLiveRecall(value) {
      this.$http({
        url: '/Record/setRecal',
        data: Object.assign({}, this.post1, {
          live_recall: value,
        }),
        isMultipleSelf: 'replacePrev',
      })
    },

    // 获取地理位置数据
    mapData() {
      let val = this.$route.query.isopencourse
        ? { live_history_id: this.$route.query.sl_id }
        : {
            sl_id: this.$route.query.sl_id,
          }
      this.$http({
        url: '/Record/liveCity',
        data: _.assign({}, val, {
          type: this.course_type,
        }),
        callback: ({ data }) => {
          this.mapDistribution = data
        },
      })
    },

    toggleCourseType(type) {
      if (this.course_type != type) {
        this.course_type = type
        this.init()
      }
    },

    timepick(val) {
      val = val || [0, 0]
      const v1 = Math.floor(val[0] / 1000)
      const v2 = Math.floor(val[1] / 1000)

      this.post1.startTime = v1
      this.post1.endTime = v2 ? v2 + 86399 : 0
    },

    // 导出
    exportTable() {
      const verify = this.liveDetailsCount

      if (!this.post1.startTime || !this.post1.endTime) {
        this.$root.prompt('请选择时间段导出')
        return
      }

      if (this.post1.endTime - this.post1.startTime > 30 * 86400) {
        this.$root.prompt({
          msg: '最多支持30天的数据导出，当前区间超过30天，请重新选择！',
          type: 'warning',
        })
        return
      }

      if (verify > 0) {
        this.$confirm(`确定要导出当前${verify}条数据？`, '提示', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          // 所有搜索条件get传递过去
          let query = JSON.stringify(_.assign({}, this.post1))

          this.$store.dispatch(
            'open',
            `${location.origin}/record/exportExcel?query=${encodeURIComponent(
              query
            )}`
          )
        })
      } else {
        this.$root.prompt('暂无数据，无法导出！')
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.line-chart {
  margin-left: 20px;
  height: 305px;
  position: relative;
}
.title {
  font-size: 14px;
  font-weight: bold;
  color: rgba(51, 51, 51, 1);
  line-height: 19px;
}
.course-name {
  padding: 20px;
  background: #fff;
  .name {
    .t1 {
      font-size: 14px;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
      line-height: 19px;
    }
    .t2 {
      height: 16px;
      font-size: 10px;
      margin-left: 4px;
      border-radius: 0px;
      line-height: 18px;
      text-align: center;
      padding: 0 2px;
      color: rgba(27, 157, 151, 1);
      border: 1px solid rgba(27, 157, 151, 1);
    }
  }
  .time {
    font-size: 12px;
    margin-top: 12px;
    color: rgba(102, 102, 102, 1);
    line-height: 16px;
  }
}
.flex-center.flex-list {
  padding-right: 20px;
}
.map,
.personnelRatio {
  flex: 1;
  height: 354px;
  padding: 20px;
  background: #fff;
  position: relative;
  box-sizing: border-box;
}
.function-views {
  > div:nth-child(2n) {
    margin-left: 20px;
  }
}
.toDetail {
  font-size: 14px;
  color: $color;
  line-height: 19px;
  text-decoration: none;
}
.invite,
.reward {
  flex: 1;
  max-width: 100%;
  padding: 20px;
  max-width: 100%;
  background: #fff;
  box-sizing: border-box;
  .table-menu {
    margin: 20px 0;
    .item {
      flex: 1;
      .title {
        font-size: 13px;
        line-height: 17px;
        color: rgba(51, 51, 51, 1);
      }
      .number {
        font-size: 24px;
        font-weight: bold;
        line-height: 31px;
        margin-top: 6px;
        color: rgba(51, 51, 51, 1);
      }
    }
  }
}
.invite-nick {
  .photo {
    width: 20px;
    height: 20px;
    border-radius: 10px;
  }
  .name {
    font-size: 14px;
    margin-left: 10px;
    line-height: 19px;
    color: rgba(51, 51, 51, 1);
  }
}
.toggleCourseType {
  border-bottom: 1px solid #eeeeee;
  .item {
    font-size: 14px;
    line-height: 19px;
    padding: 14px 20px;
    cursor: pointer;
    color: rgba(102, 102, 102, 1);
    &.selected {
      color: $color;
      border-bottom: 1px solid $color;
    }
  }
}
::v-deep .back-video-setting {
  padding: 14px 20px 30px 20px;
  .el-switch {
    margin: 0 30px 0 60px;
  }
  .prompt {
    font-size: 13px;
    color: rgba(153, 153, 153, 1);
    line-height: 17px;
  }
}
</style>
